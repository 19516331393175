<template>
    <div class="data-content">
        <div class="searchbar">
            <div class="pr10">
                <el-button
                    type="primary"
                    size="small"
                    class="table-toolbar-btn"
                    @click="handleExport"
                > 导出 </el-button>
            </div>
            <el-form
                ref="form"
                :inline="true"
                label-width="86px"
                size="small"
                :show-message="false"
                class="search-form"
            >
                <el-form-item label="关键字">
                    <el-input v-model="searchKey" clearable placeholder="请输入关键字"></el-input>
                </el-form-item>
                <el-form-item label="维修区分">
                    <el-select v-model="repairType" placeholder="全部" class="search-input">
                        <el-option
                            v-for="option in repairTypeOptions"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="投影仪">
                    <el-select class="search-input" size="small" v-model="isProjector" filterable placeholder="全部">
                        <el-option value="0" label="否"></el-option>
                        <el-option value="1" label="是"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                        v-model="startTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择"
                        class="dt-picker"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                        v-model="endTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择"
                        class="dt-picker"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="dealFlag"
                               placeholder="全部"
                               class="search-select">
                        <el-option
                            v-for="option in queryDealFlagOptions"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-button class="search-btn" @click="getDataList"></el-button>
                <el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
            </el-form>
        </div>
        <div class="data-list-box">
            <el-table
                :data="dataList"
                :row-class-name="tableRowClassName"
                :header-cell-style="{color:'#444'}"
                class="scroll-table"
                style="width: 100%;">
                <el-table-column
                    label="NO."
                    width="60">
                    <template slot-scope="scope">
                        <span class="no">{{scope.$index+1}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="serialNumber"
                    label="流水号"
                    width="180"
                ></el-table-column>
				<el-table-column
					prop="dealerName"
					label="维修店名"
					width="180"
					show-overflow-tooltip
				></el-table-column>
                <el-table-column
                    prop="serialNumber"
                    label="节点"
                    width="140"
                >
                    <template slot-scope="scope">{{ getAuditStatusText(scope.row) }}</template>
                </el-table-column>
                <el-table-column
                    prop="repairType"
                    label="维修区分"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="getRepairTypeOption(scope.row)" :type="getRepairTypeOption(scope.row).type">{{ getRepairTypeOption(scope.row).name }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="machineNo"
                    label="机号"
                    width="150"
                ></el-table-column>
                <el-table-column
                    prop="modelName"
                    label="机型"
                    min-width="180"
                ></el-table-column>
                <el-table-column
                    prop="contactPerson"
                    label="送修人/保修人"
                    width="140"
                ></el-table-column>
                <el-table-column
                    prop="contactPhone"
                    label="联系电话"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="repairAcceptDate"
                    label="维修受理日期"
                    width="120"
                >
					<template slot-scope="scope"> {{ scope.row.repairAcceptDate ? formatTimestamp(scope.row.repairAcceptDate) : ""}} </template>
                </el-table-column>
                <el-table-column
                    prop="serviceType"
                    label="服务形式"
                    width="100"
                >
                    <template slot-scope="scope"> {{ getServiceTypeText(scope.row) }} </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="70">
                    <template slot-scope="scope">
                        <button class="btn-blue" @click="handleView(scope.row)"> 查看 </button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrapper pa25">
                <Pagination
                    :page.sync="pageNo"
                    :pageSize.sync="pageSize"
                    :total="total"
                    @sizeChange="handleSizeChange"
                    @currentChange="handleCurrentChange">
                </Pagination>
            </div>
        </div>
        <ApprovalConfirm ref="approval" @refresh="getDataList"></ApprovalConfirm>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
	repairTypeOptions,
	serviceTypeOptions,
	repairAuditStatusOptions, queryDealFlagOptions,
} from "@/constant/options"
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"
import ApprovalConfirm from "../ApprovalConfirm.vue"
import LogisticsShipment from "./components/LogisticsShipment"

export default {
    components: {
        Pagination,
        ApprovalConfirm,
        LogisticsShipment,
    },
    data() {
        return {
            dataList: [],
            searchKey: '',
            pageNo: 1,
            pageSize: 10,
            total: 0,
			repairType:'', //维修区分
			isProjector: '', //是否投影仪
			dealFlag: '1', //当前状态
            startTime: '',      // 开始时间
            endTime: '',        // 结束时间
            repairTypeOptions,
            serviceTypeOptions,
            repairAuditStatusOptions,
            repairActionEnum,
			queryDealFlagOptions
        }
    },
    created() {
        this.getDataList()
    },
	computed: {
	},
    methods: {
        // NO.隔行变色
        tableRowClassName({row,rowIndex}) {
            if(rowIndex%2 == 1) {
                return 'blue'
            }else {
                return 'yellow'
            }
        },
        formatTimestamp(ms, datetime) {
            const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
            return this.$moment(new Date(ms)).format(_fmt)
        },
        getAuditStatusText(row) {
            if (row?.currentAudit?.auditStatus) {
                for (const option of this.repairAuditStatusOptions) {
                    if (option.id === row.currentAudit.auditStatus) {
                        return option.name
                    }
                }
            }
            return "待提交"
        },
        getRepairTypeOption(row) {
            for (const option of this.repairTypeOptions) {
                if (option.id === row.repairType) {
                    return option
                }
            }
        },
        getServiceTypeText(row) {
            for (const option of this.serviceTypeOptions) {
                if (option.id === row.serviceType) {
                    return option.name
                }
            }
        },
        handleSizeChange() {
            this.getDataList();
        },
        handleCurrentChange() {
            this.getDataList();
        },
        handleSearch() {
            this.pageNo = 1;
            this.getDataList();
        },
        handleView(data) {
            this.$router.push({
                name: 'repairRequestDetail',
                query: {
                    id: data.id,
					menuAction: 'claimantLogistics'
				}
            })
        },
        // 重置查询条件
        handleReset() {
            this.pageNo = 1
            this.searchKey = ''
			this.repairType = ''
			this.isProjector = ''
			this.dealFlag = '1'
            this.startTime = ''
            this.endTime = ''
            this.getDataList()
        },
        getDataList() {
            let that = this
            that.$request.post(
                "getClaimLogisticsPageList",
                true,
                {
                    searchKey: that.searchKey,
                    pageNo: that.pageNo,
                    pageSize: that.pageSize,
					repairType: that.repairType,
					isProjector: that.isProjector,
					dealFlag: that.dealFlag,
                    startTime: that.startTime,
                    endTime: that.endTime,
                },
                function (r) {
                    if (r.code == "0") {
                        that.dataList = r.data.list
                        that.total = r.data.totalCount
                    } else {
                        that.$message.error(r.msg)
                    }
                },
                function () {
                }
            )
        },
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"claimLogisticsExport",
				true,
				{
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					repairType: that.repairType,
					isProjector: that.isProjector,
					dealFlag: that.dealFlag,
                    startTime: that.startTime,
                    endTime: that.endTime,
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		}
    }
}
</script>

<style lang="scss" scoped>
.block{
    display: block;
}
.data-content{
    .top-box{
        // height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .add-btn{
        display: inline-block;
        width: 66px;
        height: 32px;
        line-height: 32px;
        border: none;
        background-color: #117FFC;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
    }
    .import-btn{
        display: inline-block;
        width: 66px;
        height: 32px;
        line-height: 32px;
        border: none;
        background-color: rgba(17, 127, 252, 0.2);
        border-radius: 4px;
        color: #117FFC;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
    }
    .template-btn{
        display: inline-block;
        width: 88px;
        height: 32px;
        line-height: 32px;
        border: none;
        background-color: rgba(17, 127, 252, 0.2);
        border-radius: 4px;
        color: #117FFC;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
    }
    .top-right-box{
		display: flex;
		flex-direction: row;
		align-items: center;

        .label{
			font-size: 14px;
			color: #606266;
            margin-right: 5px;
        }
		.search-select{
			width: 200px;
			margin: 0 10px;
		}
        .search-input{
            width: 138px;
            margin: 0 10px;
        }
        .search-btn{
            width: 32px;
            vertical-align: middle;
            cursor: pointer;
        }
        .refresh-btn{
            width: 32px;
            height: 32px;
            border: none;
            color: #117FFC;
            padding: 0;
            background-color: rgba(17, 127, 252, 0.2);
            font-weight: bold;
            font-size: 22px;
            vertical-align: bottom;
            margin-left: 5px;
        }
		::v-deep .el-input__inner {
			height: 32px;
			line-height: 32px;
		}

		::v-deep .el-input__icon {
			line-height: 32px;
		}

		::v-deep .el-range-editor.el-input__inner {
			padding: 0 3px;
		}
    }
    .btn-blue{
        font-size: 12px;
        font-weight: bold;
        color: #1081FD;
        text-decoration: underline;
        border: none;
        cursor: pointer;
        background-color: #fff;
        margin-right: 10px;
    }
    .btn-red{
        font-size: 12px;
        font-weight: bold;
        color: #E9515E;
        text-decoration: underline;
        border: none;
        cursor: pointer;
        background-color: #fff;
        margin-right: 10px;
    }
}
.dialog-title{
    display: block;
    text-align: center;
    span{
        color: #222;
        font-weight: 600;
        font-size: 20px;
    }
    img{
        width: 28px;
        float: right;
        cursor: pointer;
    }
}
.error-list{
    height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
    li{
        list-style: none;
    }
}
.searchbar {

    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner {
        width: 228px;
    }

    .table-toolbar-btn {
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
.search-btn {
    float: unset !important;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 10px !important;
    vertical-align: top;
}
.refresh-btn {
    width: 32px;
    height: 32px;
    border: none;
    color: #117FFC;
    padding: 0;
    background-color: rgba(17, 127, 252, 0.2);
    font-weight: bold;
    font-size: 22px;
    vertical-align: 8px;
    margin-left: 5px;

    &:hover {
        background-color: rgba(17, 127, 252, 0.3);
    }
}
@media screen and (max-width: 1998px) {
    .search-form {
        max-width: 980px;
    }
}

@media screen and (max-width: 1113px) {
    .search-form {
        max-width: 444px;
    }
}

@media screen and (max-width: 723px) {
    .search-form {
        max-width: 312px;
    }
}
.dt-picker {
    min-width: 190px;
}
</style>

<style>
.el-table__fixed-right::before{
	background-color:transparent !important ;
}
.scroll-table {
	border: 1px solid #dcdfe6;
}
.scroll-table .yellow{
    background-color: rgba(255, 255, 255);
}
.scroll-table .blue{
    background-color: rgba(246, 249, 253);
}
</style>
